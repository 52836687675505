import Carousel from 'nuka-carousel';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { imageOptimize } from 'src/service/image';
import dayHepler from 'src/heplers/day';
import Link from 'next/link';
import { useState, useEffect } from 'react';
import { fetchExploreMainProject } from 'src/app/explore/service';

const Reppl = ({ rid }: { rid: string }) => {
  const isMobile = useIsTablet();
  const [mainProject, setMainProject] = useState<any>();
  const rand = Date.now();

  useEffect(() => {
    const getExplore = async () => {
      const fetchedData = await fetchExploreMainProject(rid);
      setMainProject(fetchedData);
    };

    getExplore();
  }, []);

  return (
    <div
      style={{
        maxWidth: '63rem',
        minWidth: '250px',
        height: `${isMobile ? '348px' : '29rem'}`,
      }}
      className={`text-white ${
        isMobile ? 'ml-5' : 'mx-5'
      } md:mx-auto rounded-3xl border overflow-hidden h-full`}
    >
      <Link prefetch={false} href={`/reppl/${rid}`}>
        <div
          style={{ height: `${isMobile ? '100%' : '65%'}` }}
          className={`${
            isMobile ? 'px-7 pt-7' : 'px-10 pt-10'
          } reppl-image-box${rand} relative cursor-pointer`}
        >
          <div className="font-semibold text-xl md:text-2xl">
            {mainProject?.title}
          </div>
          <div className="font-medium text-gray-300 pt-3 text-sm md:text-md">
            {mainProject?.summary}
          </div>
          <div className="font-medium text-gray-300 absolute bottom-6 md:bottom-8">
            <span className="font-bold">{mainProject?.episodesCount}</span>{' '}
            creations
          </div>
        </div>
      </Link>
      {mainProject?.posts?.length > 0 && (
        <div
          style={{ height: '35%' }}
          className={`${isMobile ? 'hidden' : 'block'} bg-gray-600 h-full pt-3`}
        >
          <div className="h-full">
            <Carousel
              slidesToShow={3}
              cellSpacing={50}
              transitionMode="scroll3d"
              wrapAround
              zoomScale={0.89}
              cellAlign="center"
              autoplay
              renderCenterLeftControls={() => null}
              renderCenterRightControls={() => null}
              defaultControlsConfig={{
                pagingDotsContainerClassName: 'topPx',
                pagingDotsClassName: 'px-0.5',
                pagingDotsStyle: {
                  fill: 'white',
                },
              }}
            >
              {mainProject?.posts?.map((d, key) => {
                const mkey = `ca_${key}`;
                return (
                  <ItemsWithin key={mkey} index={mkey} itemData={d} rid={rid} />
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
      <style jsx global>{`
        .topPx {
          top: -8px !important;
        }
        .reppl-image-box${rand} {
          background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)),
            url('${imageOptimize(mainProject?.coverImgUrl)}');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      `}</style>
    </div>
  );
};
export default Reppl;

const ItemsWithin = ({
  itemData,
  index,
  rid,
}: {
  itemData?: any;
  index: string;
  rid: string;
}) => {
  const rand = Date.now();
  return (
    <>
      <Link
        prefetch={false}
        href={`/reppl/${rid}/episode/${itemData.content_id}`}
      >
        <div
          style={{ width: '320px', height: '105px' }}
          className="bg-white rounded-lg flex w-full px-4 py-5"
        >
          <div style={{ width: '60%' }} className="line-clamp-2 pr-2">
            <div className="text-sm text-gray-900">{itemData.title}</div>
            <div className="text-xs text-gray-500 pt-1">
              {dayHepler.fromNow(itemData.createdAt)}
            </div>
          </div>
          <div
            style={{ width: '40%' }}
            className={`${index}-box-${rand}-${rid} rounded bg-gray-300 h-auto`}
          />
          <style jsx global>{`
            .${index}-box-${rand}-${rid} {
              background: url('${imageOptimize(itemData?.coverImgUrl)}');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          `}</style>
        </div>
      </Link>
    </>
  );
};
